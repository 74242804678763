<template>
  <!-- Chart-->
  <div class="card">
    <div class="card-body">
      <h4 class="header-title mb-3">Flights</h4>
      <div class="chartjs-chart">
        <barChart :height="260" :pilotData="pilotData" />
      </div>
    </div>
  </div>
  <!-- End Chart-->
</template>
<script>
import barChart from "./barChart";

export default {
  components: { barChart },
  props: {
    pilotData: {
      type: Object,
    },
  },
};
</script>
