<script>
import { Bar } from "vue-chartjs";

export default {
  props: {
    pilotData: {
      type: Object,
    },
  },
  extends: Bar,
  data() {
    return {
      datasets: [],
      c: [],
      p: [],
      y: [],
      t: [],
    };
  },
  mounted() {
    if (this.pilotData.cpy_stats.length > 0) {
      this.pilotData.cpy_stats.forEach((a) => {
        if (a.flight_type === "Classic") {
          this.c.splice(0, 0, a.c);
          this.p.splice(0, 0, a.p);
          this.y.splice(0, 0, a.y);
          this.t.splice(0, 0, a.total_flights);
        } else if (a.flight_type === "Prime") {
          this.c.splice(1, 0, a.c);
          this.p.splice(1, 0, a.p);
          this.y.splice(1, 0, a.y);
          this.t.splice(1, 0, a.total_flights);
        } else if (a.flight_type === "XC") {
          this.c.splice(2, 0, a.c);
          this.p.splice(2, 0, a.p);
          this.y.splice(2, 0, a.y);
          this.t.splice(2, 0, a.total_flights);
        }
      });
      {
        this.datasets.push({
          barPercentage: 0.7,
          categoryPercentage: 0.5,
          label: "C",
          backgroundColor: "#727cf5",
          borderColor: "#727cf5",
          hoverBackgroundColor: "#727cf5",
          hoverBorderColor: "#727cf5",
          data: this.c,
        });
      }
      {
        this.datasets.push({
          barPercentage: 0.7,
          categoryPercentage: 0.5,
          label: "P",
          backgroundColor: "#727cf5",
          borderColor: "#727cf5",
          hoverBackgroundColor: "#727cf5",
          hoverBorderColor: "#727cf5",
          data: this.p,
        });
      }
      {
        this.datasets.push({
          barPercentage: 0.7,
          categoryPercentage: 0.5,
          label: "Y",
          backgroundColor: "#727cf5",
          borderColor: "#727cf5",
          hoverBackgroundColor: "#727cf5",
          hoverBorderColor: "#727cf5",
          data: this.y,
        });
      }
      {
        this.datasets.push({
          barPercentage: 0.7,
          categoryPercentage: 0.5,
          label: "total flights",
          backgroundColor: "#727cf5",
          borderColor: "#727cf5",
          hoverBackgroundColor: "#727cf5",
          hoverBorderColor: "#727cf5",
          data: this.t,
        });
      }
    }
    this.renderChart(
      {
        labels: ["Classic", "Prime", "XC"],
        datasets: this.datasets,
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                display: false,
                color: "rgba(0,0,0,0.05)",
              },
              stacked: false,
              ticks: {
                stepSize: 20,
              },
            },
          ],
          xAxes: [
            {
              stacked: false,
              gridLines: {
                color: "rgba(0,0,0,0.01)",
              },
            },
          ],
        },
      }
    );
  },
};
</script>
