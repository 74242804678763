<template>
  <div class="media">
    <span class="float-left m-2 mr-4">
      <img
        :src="getImageUrl(pilotData.user.avatar)"
        style="height: 100px"
        alt
        class="rounded-circle img-thumbnail"
      />
    </span>
    <div class="media-body">
      <h4 :class="`mt-1 mb-1 text-${textColor}`">
        Captain {{ pilotData.user.name }}
      </h4>
      <p :class="`font-13 text-${textColor}-50`">
        {{ pilotData.pilot_type }} Pilot
      </p>

      <ul :class="`mb-0 list-inline text-${textColor}`">
        <li class="list-inline-item mr-3">
          <h5 class="mb-1">₹ -</h5>
          <p :class="`mb-0 font-13 text-${textColor}`">Total Revenue</p>
        </li>
        <li class="list-inline-item">
          <h5 class="mb-1">
            {{ pilotData.cpy_stats_aggregate.aggregate.sum.total_flights || 0 }}
          </h5>
          <p :class="`mb-0 font-13 text-${textColor}`">Number of Flights</p>
        </li>
      </ul>
    </div>
    <!-- end media-body-->
  </div>
  <!-- end media -->
</template>
<script>
export default {
  name: "profilepilot",
  props: {
    textColor: {
      type: String,
      default: "white",
    },
    pilotData: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {
    getImageUrl(url) {
      return url ? url : "/img/default-avatar.jpg";
    },
  },
};
</script>
